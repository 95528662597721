<template>
  <div :class="containerClass">
    <div :class="contentClass">
      <div class="tw-flex tw-items-center tw-justify-center">
        <img
          :class="[
            'tw-h-16 tw-w-16',
            smallPadding ? 'tw-mr-2 lg:tw-mr-4' : 'tw-mr-4',
          ]"
          src="~/assets/img/AllAccessInverted.png"
        >
        <h2 class="tw-text-lg tw-font-normal">
          {{ headerText }}
        </h2>
      </div>
      <!-- web buttons -->
      <div
        v-if="!inAppView"
        class="tw-flex tw-flex-col tw-items-center tw-mt-4"
      >
        <Button v-bind="webButtonProps">
          Start Free Trial
          <small class="tw-block tw-whitespace-nowrap">No credit card required</small>
        </Button>
        <span v-if="isGuest">
          Already have an account?
          <NuxtLink
            class="tw-underline tw-font-medium"
            :to="loginUrl"
          >
            Log In
          </NuxtLink>
        </span>
      </div>
      <!-- app looks for certain URLs to handle manually so needs its own buttons -->
      <div
        v-if="inAppView"
        class="tw-flex tw-flex-col tw-items-center tw-mt-4"
      >
        <Button v-bind="appTrialButtonProps">
          Start Free Trial
          <small class="tw-block tw-whitespace-nowrap">No credit card required</small>
        </Button>
        <Button
          v-if="!isGuest"
          v-bind="appBuyButtonProps"
        >
          Buy Now
        </Button>
      </div>
    </div>
    <div
      v-if="showBenefits"
      :class="benefitsClass"
    >
      <p class="tw-mb-2">
        {{ benefitsText }}
      </p>
      <ul class="tw-mb-4 tw-ml-4 tw-list-disc tw-list-outside">
        <li
          v-for="(upgradeReason, index) in upgradeReasons"
          :key="index"
          class="tw-mb-1"
        >
          {{ upgradeReason.title }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { addInAppViewToPath, addQueryParamsToPath } from '@@/utils/CommonUtils';
import { ButtonTypes } from '@@/components/Common/Button.vue';
import { getStartFreeTrialUrl } from '@@/utils/LoginUtils';

export default {
  name: 'UpgradeToAllAccessCard',

  props: {
    benefitsText: {
      type: String,
      default: 'Upgrade to All-Access and receive exclusive benefits:',
    },
    headerText: {
      type: String,
      default: 'Want to see more?',
    },
    isGuest: {
      type: Boolean,
      default: true,
    },
    inAppView: {
      type: Boolean,
      default: false,
    },
    returnTo: {
      type: String,
      default: null,
    },
    showBenefits: {
      type: Boolean,
      default: true,
    },
    smallPadding: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: null,
    },
    upgradeReasons: {
      type: Array,
      default() { return []; },
    },
  },

  computed: {
    benefitsClass() {
      return [
        'tw-hidden',
        this.showBenefits ? 'md:tw-block md:tw-ml-6 lg:tw-ml-12' : null,
      ];
    },

    webButtonProps() {
      const params = {
        isGuest: this.isGuest,
        query: {
          return_to: this.returnTo,
          source: this.source,
        },
      };

      const upgradeUrl = getStartFreeTrialUrl(params);
      return {
        additionalClassNames: 'tw-mb-6',
        to: upgradeUrl,
        type: ButtonTypes.upgrade,
      };
    },

    appTrialButtonProps() {
      const params = {
        return_to: addInAppViewToPath(this.returnTo, true),
        source: this.source,
      };

      // app buttons have to be rendered as regular links to be handled properly by the app
      return {
        additionalClassNames: 'tw-mb-4 tw-w-full',
        href: addQueryParamsToPath('/user/settings/start-trial', params),
        type: ButtonTypes.upgrade,
      };
    },

    appBuyButtonProps() {
      const params = {
        return_to: addInAppViewToPath(this.returnTo, true),
        source: this.source,
      };

      // app buttons have to be rendered as regular links to be handled properly by the app
      return {
        additionalClassNames: 'tw-mb-6 tw-w-full',
        href: addQueryParamsToPath('/user/settings/upgrade', params),
        type: ButtonTypes.homePageBuyNow,
      };
    },

    containerClass() {
      return [
        'tw-flex',
        this.showBenefits ? 'tw-justify-center md:tw-justify-start' : 'tw-justify-center',
        this.smallPadding ? 'tw-p-2' : 'tw-p-7 lg:tw-py-5 lg:tw-px-12',
        'tw-rounded-lg',
        'tw-text-white',
        'all-access-background-color',
      ];
    },

    contentClass() {
      return [
        'tw-flex tw-flex-col',
        this.showBenefits ? 'md:tw-w-1/2' : null,
      ];
    },

    loginUrl() {
      return addQueryParamsToPath('/user/login', { return_to: this.returnTo });
    },
  },
};
</script>
